export const environment = {
  production: true,
  countryPrefixActivated: false,
  claimApi: {
    baseUrl: 'https://staging.lamie.systems', // NEEDS working CORS
    staticHeaders: {
      subscriptionKey: '{134D3786-ED4E-E911-80EF-00155D257FCF}',
      product: 'TravelInsurance',
      userPrincipalId: 'a1svn-claimwebsite',
    },
  },
  authApi: {
    baseUrl: 'https://staging.lamie.systems', // NEEDS working CORS
  },
  fileApi: {
    baseUrl: 'https://staging.lamie.systems', // NEEDS working CORS
    staticHeaders: {
      subscriptionKey: '{134D3786-ED4E-E911-80EF-00155D257FCF}',
      product: 'TravelInsurance',
      userPrincipalId: 'a1svn-claimwebsite',
    },
  },
  policyApi: {
    baseUrl: 'https://staging.lamie.systems', // NEEDS working CORS
    staticHeaders: {
      subscriptionKey: '{134D3786-ED4E-E911-80EF-00155D257FCF}',
      product: 'TravelInsurance',
      userPrincipalId: 'a1svn-claimwebsite',
    },
  },
};
