import { Injectable, signal } from '@angular/core';
import { PolicyApi } from '@integral/shared/backends/policy';

type FormValueType = {
  step1: {
    token: string;
    verificationCode: string;
    customerIdentifier: string;
    captchaSolution: string;
    claimEligibilityInfo: {
      insuranceContractType: string;
      coverFrom: string;
    };
  };
  step2: {
    claimAffectedPerson: {
      gender: PolicyApi.Gender;
      firstName: string;
      lastName: string;
      addressLine1: string;
      postalCode: string;
      city: string;
      dateOfBirth: string;
      nationalIdentificationNumber: string;
      email: string;
      phoneNumber: string;
      type: string;
    };
    contractHolder: string;
  };
  step3: {
    name: string;
    content: string;
    mimeType: string;
  };
};

@Injectable({ providedIn: 'root' })
export class LiabilityInsuranceConfirmationService {
  fakeResponse: PolicyApi.CoreSystemFile = {
    name: 'TI-A1SVN-ConfirmationOnLiabilityInsurance.pdf',
    content: 'JVBERi0xLjUNCjQgMCBvYmoNCj ....',
    mimeType: 'application/pdf',
  };

  insurancePolicy = signal(undefined as PolicyApi.CoreSystemFile | undefined);

  parseLiabilityInsuranceConfirmationFormData(
    formValue: FormValueType,
  ): PolicyApi.InsuranceConfirmationRequestDto {
    const insuranceConfirmationRequest: PolicyApi.InsuranceConfirmationRequestDto =
      {
        insuranceContractType:
          formValue.step1.claimEligibilityInfo.insuranceContractType,
        customerIdentifier: formValue.step1.customerIdentifier,
        mainInsuredPerson: {
          gender: formValue.step2.claimAffectedPerson.gender,
          firstName: formValue.step2.claimAffectedPerson.firstName,
          lastName: formValue.step2.claimAffectedPerson.lastName,
          address: {
            addressLine1: formValue.step2.claimAffectedPerson.addressLine1,
            postalCode: formValue.step2.claimAffectedPerson.postalCode,
            city: formValue.step2.claimAffectedPerson.city,
          },
          dateOfBirth: formValue.step2.claimAffectedPerson.dateOfBirth,
          email: formValue.step2.claimAffectedPerson.email,
          phoneNumber: formValue.step2.claimAffectedPerson.phoneNumber,
        },
      };
    return insuranceConfirmationRequest;
  }

  transform64toBlob(
    insurancePolicyContent: string,
    insurancePolicyType: string,
  ): Blob {
    const byteCharacters = atob(insurancePolicyContent);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], {
      type: insurancePolicyType,
    });
    return blob;
  }
}
