/**
 * Policy Services API
 * The Policy Services API provides methods for activating new as well as managing and canceling existing insurance contracts.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { AbortOrderingProcessDto } from '../model/abortOrderingProcessDto';
// @ts-ignore
import { ConfirmProposalDto } from '../model/confirmProposalDto';
// @ts-ignore
import { EmptyResultApiResponse } from '../model/emptyResultApiResponse';
// @ts-ignore
import { ErrorResponse } from '../model/errorResponse';
// @ts-ignore
import { ProblemDetails } from '../model/problemDetails';
// @ts-ignore
import { ProposalDto } from '../model/proposalDto';
// @ts-ignore
import { ProposalValuesDtoApiResponse } from '../model/proposalValuesDtoApiResponse';
// @ts-ignore
import { SendLegalInformationRequestDto } from '../model/sendLegalInformationRequestDto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface PolicyApiV1ProposalsAbortPostRequestParams {
    /** Unique correlationId to identify partner requests throughout all called services */
    correlationId: string;
    /** Id to identify from which user the requests is comming from. External partners use the provided partner key */
    userPrincipalId: string;
    product?: string;
    abortOrderingProcessDto?: AbortOrderingProcessDto;
}

export interface PolicyApiV1ProposalsConfirmsignaturePostRequestParams {
    /** Unique correlationId to identify partner requests throughout all called services */
    correlationId: string;
    /** Id to identify from which user the requests is comming from. External partners use the provided partner key */
    userPrincipalId: string;
    product?: string;
    confirmProposalDto?: ConfirmProposalDto;
}

export interface PolicyApiV1ProposalsGetbyidGetRequestParams {
    /** Unique correlationId to identify partner requests throughout all called services */
    correlationId: string;
    /** Id to identify from which user the requests is comming from. External partners use the provided partner key */
    userPrincipalId: string;
    id?: string;
    product?: string;
}

export interface PolicyApiV1ProposalsRequestproposallinkPostRequestParams {
    /** Unique correlationId to identify partner requests throughout all called services */
    correlationId: string;
    /** Id to identify from which user the requests is comming from. External partners use the provided partner key */
    userPrincipalId: string;
    product?: string;
    sendLink?: boolean;
    proposalDto?: ProposalDto;
}

export interface PolicyApiV1ProposalsRequestproposalvaluesPostRequestParams {
    /** Unique correlationId to identify partner requests throughout all called services */
    correlationId: string;
    /** Id to identify from which user the requests is comming from. External partners use the provided partner key */
    userPrincipalId: string;
    product?: string;
    proposalDto?: ProposalDto;
}

export interface PolicyApiV1ProposalsSendlegalinformationPostRequestParams {
    /** Unique correlationId to identify partner requests throughout all called services */
    correlationId: string;
    /** Id to identify from which user the requests is comming from. External partners use the provided partner key */
    userPrincipalId: string;
    product?: string;
    sendLegalInformationRequestDto?: SendLegalInformationRequestDto;
}


@Injectable({
  providedIn: 'root'
})
export class ProposalService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Aborts ordering process by deleting existing proposal
     * Existing proposal is recognized by passed proposalIdentifier and deleted
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyApiV1ProposalsAbortPost(requestParameters: PolicyApiV1ProposalsAbortPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public policyApiV1ProposalsAbortPost(requestParameters: PolicyApiV1ProposalsAbortPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public policyApiV1ProposalsAbortPost(requestParameters: PolicyApiV1ProposalsAbortPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public policyApiV1ProposalsAbortPost(requestParameters: PolicyApiV1ProposalsAbortPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const correlationId = requestParameters.correlationId;
        if (correlationId === null || correlationId === undefined) {
            throw new Error('Required parameter correlationId was null or undefined when calling policyApiV1ProposalsAbortPost.');
        }
        const userPrincipalId = requestParameters.userPrincipalId;
        if (userPrincipalId === null || userPrincipalId === undefined) {
            throw new Error('Required parameter userPrincipalId was null or undefined when calling policyApiV1ProposalsAbortPost.');
        }
        const product = requestParameters.product;
        const abortOrderingProcessDto = requestParameters.abortOrderingProcessDto;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }

        let localVarHeaders = this.defaultHeaders;
        if (correlationId !== undefined && correlationId !== null) {
            localVarHeaders = localVarHeaders.set('CorrelationId', String(correlationId));
        }
        if (userPrincipalId !== undefined && userPrincipalId !== null) {
            localVarHeaders = localVarHeaders.set('UserPrincipalId', String(userPrincipalId));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/policy/api/v1/proposals/abort`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: abortOrderingProcessDto,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyApiV1ProposalsConfirmsignaturePost(requestParameters: PolicyApiV1ProposalsConfirmsignaturePostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<EmptyResultApiResponse>;
    public policyApiV1ProposalsConfirmsignaturePost(requestParameters: PolicyApiV1ProposalsConfirmsignaturePostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<EmptyResultApiResponse>>;
    public policyApiV1ProposalsConfirmsignaturePost(requestParameters: PolicyApiV1ProposalsConfirmsignaturePostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<EmptyResultApiResponse>>;
    public policyApiV1ProposalsConfirmsignaturePost(requestParameters: PolicyApiV1ProposalsConfirmsignaturePostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const correlationId = requestParameters.correlationId;
        if (correlationId === null || correlationId === undefined) {
            throw new Error('Required parameter correlationId was null or undefined when calling policyApiV1ProposalsConfirmsignaturePost.');
        }
        const userPrincipalId = requestParameters.userPrincipalId;
        if (userPrincipalId === null || userPrincipalId === undefined) {
            throw new Error('Required parameter userPrincipalId was null or undefined when calling policyApiV1ProposalsConfirmsignaturePost.');
        }
        const product = requestParameters.product;
        const confirmProposalDto = requestParameters.confirmProposalDto;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }

        let localVarHeaders = this.defaultHeaders;
        if (correlationId !== undefined && correlationId !== null) {
            localVarHeaders = localVarHeaders.set('CorrelationId', String(correlationId));
        }
        if (userPrincipalId !== undefined && userPrincipalId !== null) {
            localVarHeaders = localVarHeaders.set('UserPrincipalId', String(userPrincipalId));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/policy/api/v1/proposals/confirmsignature`;
        return this.httpClient.request<EmptyResultApiResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: confirmProposalDto,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve temporarily stored customer data
     * The submitted customer- and contract data is temporarily loaded from the LAMIE system. The data is used when the customer clicks on his Proposal link to prefill the Order flow.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyApiV1ProposalsGetbyidGet(requestParameters: PolicyApiV1ProposalsGetbyidGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public policyApiV1ProposalsGetbyidGet(requestParameters: PolicyApiV1ProposalsGetbyidGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public policyApiV1ProposalsGetbyidGet(requestParameters: PolicyApiV1ProposalsGetbyidGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public policyApiV1ProposalsGetbyidGet(requestParameters: PolicyApiV1ProposalsGetbyidGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const correlationId = requestParameters.correlationId;
        if (correlationId === null || correlationId === undefined) {
            throw new Error('Required parameter correlationId was null or undefined when calling policyApiV1ProposalsGetbyidGet.');
        }
        const userPrincipalId = requestParameters.userPrincipalId;
        if (userPrincipalId === null || userPrincipalId === undefined) {
            throw new Error('Required parameter userPrincipalId was null or undefined when calling policyApiV1ProposalsGetbyidGet.');
        }
        const id = requestParameters.id;
        const product = requestParameters.product;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
        }
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }

        let localVarHeaders = this.defaultHeaders;
        if (correlationId !== undefined && correlationId !== null) {
            localVarHeaders = localVarHeaders.set('CorrelationId', String(correlationId));
        }
        if (userPrincipalId !== undefined && userPrincipalId !== null) {
            localVarHeaders = localVarHeaders.set('UserPrincipalId', String(userPrincipalId));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/policy/api/v1/proposals/getbyid`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Temporarily store customer data
     * The submitted customer- and contract data is temporarily stored in the LAMIE system. The customer receives an SMS notification with a link to the online ordering website where the customer data is already prefilled.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyApiV1ProposalsRequestproposallinkPost(requestParameters: PolicyApiV1ProposalsRequestproposallinkPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public policyApiV1ProposalsRequestproposallinkPost(requestParameters: PolicyApiV1ProposalsRequestproposallinkPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public policyApiV1ProposalsRequestproposallinkPost(requestParameters: PolicyApiV1ProposalsRequestproposallinkPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public policyApiV1ProposalsRequestproposallinkPost(requestParameters: PolicyApiV1ProposalsRequestproposallinkPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const correlationId = requestParameters.correlationId;
        if (correlationId === null || correlationId === undefined) {
            throw new Error('Required parameter correlationId was null or undefined when calling policyApiV1ProposalsRequestproposallinkPost.');
        }
        const userPrincipalId = requestParameters.userPrincipalId;
        if (userPrincipalId === null || userPrincipalId === undefined) {
            throw new Error('Required parameter userPrincipalId was null or undefined when calling policyApiV1ProposalsRequestproposallinkPost.');
        }
        const product = requestParameters.product;
        const sendLink = requestParameters.sendLink;
        const proposalDto = requestParameters.proposalDto;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }
        if (sendLink !== undefined && sendLink !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>sendLink, 'sendLink');
        }

        let localVarHeaders = this.defaultHeaders;
        if (correlationId !== undefined && correlationId !== null) {
            localVarHeaders = localVarHeaders.set('CorrelationId', String(correlationId));
        }
        if (userPrincipalId !== undefined && userPrincipalId !== null) {
            localVarHeaders = localVarHeaders.set('UserPrincipalId', String(userPrincipalId));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/policy/api/v1/proposals/requestproposallink`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: proposalDto,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Temporarily store customer data
     * The submitted customer- and contract data is temporarily stored in the LAMIE system.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyApiV1ProposalsRequestproposalvaluesPost(requestParameters: PolicyApiV1ProposalsRequestproposalvaluesPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProposalValuesDtoApiResponse>;
    public policyApiV1ProposalsRequestproposalvaluesPost(requestParameters: PolicyApiV1ProposalsRequestproposalvaluesPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProposalValuesDtoApiResponse>>;
    public policyApiV1ProposalsRequestproposalvaluesPost(requestParameters: PolicyApiV1ProposalsRequestproposalvaluesPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProposalValuesDtoApiResponse>>;
    public policyApiV1ProposalsRequestproposalvaluesPost(requestParameters: PolicyApiV1ProposalsRequestproposalvaluesPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const correlationId = requestParameters.correlationId;
        if (correlationId === null || correlationId === undefined) {
            throw new Error('Required parameter correlationId was null or undefined when calling policyApiV1ProposalsRequestproposalvaluesPost.');
        }
        const userPrincipalId = requestParameters.userPrincipalId;
        if (userPrincipalId === null || userPrincipalId === undefined) {
            throw new Error('Required parameter userPrincipalId was null or undefined when calling policyApiV1ProposalsRequestproposalvaluesPost.');
        }
        const product = requestParameters.product;
        const proposalDto = requestParameters.proposalDto;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }

        let localVarHeaders = this.defaultHeaders;
        if (correlationId !== undefined && correlationId !== null) {
            localVarHeaders = localVarHeaders.set('CorrelationId', String(correlationId));
        }
        if (userPrincipalId !== undefined && userPrincipalId !== null) {
            localVarHeaders = localVarHeaders.set('UserPrincipalId', String(userPrincipalId));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/policy/api/v1/proposals/requestproposalvalues`;
        return this.httpClient.request<ProposalValuesDtoApiResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: proposalDto,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyApiV1ProposalsSendlegalinformationPost(requestParameters: PolicyApiV1ProposalsSendlegalinformationPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public policyApiV1ProposalsSendlegalinformationPost(requestParameters: PolicyApiV1ProposalsSendlegalinformationPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public policyApiV1ProposalsSendlegalinformationPost(requestParameters: PolicyApiV1ProposalsSendlegalinformationPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public policyApiV1ProposalsSendlegalinformationPost(requestParameters: PolicyApiV1ProposalsSendlegalinformationPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const correlationId = requestParameters.correlationId;
        if (correlationId === null || correlationId === undefined) {
            throw new Error('Required parameter correlationId was null or undefined when calling policyApiV1ProposalsSendlegalinformationPost.');
        }
        const userPrincipalId = requestParameters.userPrincipalId;
        if (userPrincipalId === null || userPrincipalId === undefined) {
            throw new Error('Required parameter userPrincipalId was null or undefined when calling policyApiV1ProposalsSendlegalinformationPost.');
        }
        const product = requestParameters.product;
        const sendLegalInformationRequestDto = requestParameters.sendLegalInformationRequestDto;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }

        let localVarHeaders = this.defaultHeaders;
        if (correlationId !== undefined && correlationId !== null) {
            localVarHeaders = localVarHeaders.set('CorrelationId', String(correlationId));
        }
        if (userPrincipalId !== undefined && userPrincipalId !== null) {
            localVarHeaders = localVarHeaders.set('UserPrincipalId', String(userPrincipalId));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/policy/api/v1/proposals/sendlegalinformation`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: sendLegalInformationRequestDto,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
