import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
  signal,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  ButtonComponent,
  LamieStepperPageBaseComponent,
  LoadingOverlayStateService,
} from '@integral/shared/ui/layout';
import { LiabilityInsuranceConfirmationService } from './liability-insurance-confirmation.service';
import { PdfContent } from '@integral/shared/full-step-pages';
import { catchError, finalize, of, tap } from 'rxjs';
import {
  PolicyApi,
  PolicyApiHeadersService,
} from '@integral/shared/backends/policy';
import { FileSaverService } from 'ngx-filesaver';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-liability-end-page',
  standalone: true,
  imports: [TranslateModule, ButtonComponent],
  template: `
    @if (fileName() === 'error') {
      <div
        class="flex-nowrap flex flex-col items-center my-20 gap-12 px-6 lg:px-20"
      >
        <div class="text-error600">
          <svg width="76" height="76" class="fill-current">
            <use href="/assets/svg/error-x.svg#root" />
          </svg>
        </div>
        <h1 class="text-3xl text-error600">
          {{ 'endpage.errorTitle' | translate }}
        </h1>
        <p
          [innerHTML]="'endpage.errorText' | translate"
          class="text-center leading-loose"
        ></p>
        <integral-button
          class="ml-4"
          [text]="'endpage.restartButtonLabel' | translate"
          (click)="onClickRestart()"
        ></integral-button>
      </div>
    } @else if (fileName() === 'loading') {
      <div class="w-screen h-full flex justify-center">
        <h1 class="text-3xl">
          {{ 'endpage.creatingPolicy' | translate }}
        </h1>
      </div>
    } @else {
      <div
        class="flex-nowrap flex flex-col items-center my-20 gap-12 px-6 lg:px-20"
      >
        <div class="text-success600">
          <svg width="76" height="76" class="fill-current">
            <use href="/assets/svg/success.svg#root" />
          </svg>
        </div>
        <h1 class="text-3xl text-success600">
          {{ 'endpage.successTitle' | translate }}
        </h1>
        <p
          [innerHTML]="'endpage.successText' | translate"
          class="text-center leading-loose"
        ></p>
        <h2 [innerHTML]="'endpage.successDownloadlink' | translate"></h2>
        <button (click)="downloadPolicy()">
          <svg width="64" height="64" class="fill-current text-gray-500">
            <use href="/assets/svg/download-file.svg#root" />
          </svg>
        </button>
      </div>
    }
  `,
  styles: `
    :host {
      display: block;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LiabilityEndpageComponent
  extends LamieStepperPageBaseComponent
  implements OnInit
{
  private readonly liabilitiesService = inject(
    LiabilityInsuranceConfirmationService,
  );
  private readonly fileSaverService = inject(FileSaverService);

  @Input({ required: true }) override stepControl!: PdfContent;
  private readonly policyApiHeadersService = inject(PolicyApiHeadersService);
  private readonly policyService = inject(PolicyApi.PolicyService);
  private readonly loaderService = inject(LoadingOverlayStateService);
  protected fileName = signal('loading');
  private readonly destroyRef = inject(DestroyRef);

  override ngOnInit() {
    super.ngOnInit();
    this._showPrev.set(false);
    this.requestInsuranceConfirmation();
  }

  protected onClickRestart() {
    window.location.reload();
  }

  requestInsuranceConfirmation(): void {
    const formValue = this.stepControl.parent?.value;
    this.loaderService.showGlobalLoading();
    const requestParams = {
      ...this.policyApiHeadersService.defaultHeaders,
      lamieToken: formValue.step1.token,
      insuranceConfirmationRequestDto:
        this.liabilitiesService?.parseLiabilityInsuranceConfirmationFormData(
          formValue,
        ),
    };
    console.log('requestParams', requestParams); // TODO remove log as soon as tested
    this.policyService
      .policyApiV1PoliciesGenerateinsuranceconfirmationPost(requestParams)
      .pipe(
        tap((response) => {
          this.liabilitiesService.insurancePolicy.set(response.data);
          if (response.data && response.data.name == null) {
            this.fileName.set('error');
            return;
          }
          if (
            this.liabilitiesService &&
            this.liabilitiesService.insurancePolicy()
          ) {
            this.fileName.set(this.liabilitiesService.insurancePolicy.name);
            this.loaderService.hideGlobalLoading();
            this.stepControl.parent?.reset();
          }
        }),
        catchError((e) => {
          console.log('error', e);
          this.fileName.set('error');
          this.loaderService.hideGlobalLoading();
          this._showPrev.set(true);
          return of(true);
        }),
        finalize(() => this.loaderService.hideGlobalLoading()),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  downloadPolicy(): void {
    const insurancePolicy = this.liabilitiesService.insurancePolicy();
    if (
      !insurancePolicy ||
      !insurancePolicy.content ||
      !insurancePolicy.name ||
      !insurancePolicy.mimeType
    ) {
      return;
    }

    this.fileSaverService.save(
      this.liabilitiesService.transform64toBlob(
        insurancePolicy.content,
        insurancePolicy.mimeType,
      ),
      insurancePolicy.name,
      insurancePolicy.mimeType,
    );
  }
}
