/**
 * Policy Services API
 * The Policy Services API provides methods for activating new as well as managing and canceling existing insurance contracts.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Transmitter = 'PartnerSystem' | 'PartnerApp' | 'PartnerWeb' | 'PartnerPOS' | 'LamieAgent' | 'LamieCustomerPortal' | 'LamieSystem';

export const Transmitter = {
    PartnerSystem: 'PartnerSystem' as Transmitter,
    PartnerApp: 'PartnerApp' as Transmitter,
    PartnerWeb: 'PartnerWeb' as Transmitter,
    PartnerPos: 'PartnerPOS' as Transmitter,
    LamieAgent: 'LamieAgent' as Transmitter,
    LamieCustomerPortal: 'LamieCustomerPortal' as Transmitter,
    LamieSystem: 'LamieSystem' as Transmitter
};

