/**
 * Policy Services API
 * The Policy Services API provides methods for activating new as well as managing and canceling existing insurance contracts.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Status = 'Quote' | 'OnRisk' | 'Suspended' | 'CanceledByOperator' | 'CanceledByCustomer' | 'CanceledByInsurer';

export const Status = {
    Quote: 'Quote' as Status,
    OnRisk: 'OnRisk' as Status,
    Suspended: 'Suspended' as Status,
    CanceledByOperator: 'CanceledByOperator' as Status,
    CanceledByCustomer: 'CanceledByCustomer' as Status,
    CanceledByInsurer: 'CanceledByInsurer' as Status
};

