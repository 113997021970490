import { Injectable, InjectionToken, inject } from '@angular/core';

export interface PolicyApiHeadersStatic {
  product: string;
  subscriptionKey: string;
  userPrincipalId: string;
}

export interface PolicyApiHeaders extends PolicyApiHeadersStatic {
  correlationId: string;
}

export const POLICY_API_HEADERS_STATIC =
  new InjectionToken<PolicyApiHeadersStatic>('POLICY_API_HEADERS_STATIC');

@Injectable({
  providedIn: 'root',
})
export class PolicyApiHeadersService {
  private readonly policyApiHeadersStatic = inject(POLICY_API_HEADERS_STATIC);

  get defaultHeaders(): PolicyApiHeaders {
    return {
      ...this.policyApiHeadersStatic,
      correlationId: crypto.randomUUID(),
    };
  }
}
