import { Component, inject, isDevMode } from '@angular/core';
import {
  FormsModule,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  FullStepPersonalDataComponent,
  FullStepVerifyNumberComponent,
  liabilityInsuranceConfirmationForm,
  NumberVerificationForm,
  PersonalDataForm,
} from '@integral/shared/full-step-pages';
import { JsonPipe } from '@angular/common';
import {
  FooterComponent,
  LamieHeaderComponent,
  LamieStepperComponent,
  LoadingOverlayComponent,
} from '@integral/shared/ui/layout';
import { environment } from '../environments/environment';
import { ClaimApi } from '@integral/shared/backends/claim';
import {
  dateNotExistingValidator,
  notInTheFutureValidator,
} from '@integral/shared/util';
import { LiabilityEndpageComponent } from './pages/liability-endpage.component';

@Component({
  standalone: true,
  imports: [
    JsonPipe,
    FormsModule,
    LamieHeaderComponent,
    LamieStepperComponent,
    LoadingOverlayComponent,
    TranslateModule,
    FooterComponent,
  ],
  selector: 'app-root',
  template: `
    <div class="w-full min-h-screen bg-white relative">
      <integral-loading-overlay>
        <lamie-header />
        <lamie-stepper
          [startIdx]="startIdx"
          [steps]="steps"
          [endButtonIndex]="endButtonIndex"
        >
        </lamie-stepper>
      </integral-loading-overlay>
      <integral-footer />
    </div>

    @if (showDebugInfo) {
      <button class="border border-black bg-errorLight" (click)="fillForm()">
        wo ist kevin?</button
      ><br />
      <select [(ngModel)]="startIdx">
        @for (step of steps; track $index) {
          <option [ngValue]="$index">{{ $index + 1 }}</option>
        }
      </select>
    }
  `,
})
export class AppComponent {
  private readonly nnfb = inject(NonNullableFormBuilder);
  private readonly translate = inject(TranslateService);
  protected showDebugInfo = isDevMode();
  protected startIdx = 0;
  protected endButtonIndex = 1;

  protected readonly form: liabilityInsuranceConfirmationForm = this.nnfb.group(
    {
      step1: this.nnfb.group({
        customerIdentifier: ['', Validators.required],
        captchaSolution: ['', Validators.required],
        claimEligibilityInfo: this.nnfb.group({
          coverFrom: ['' as string | null | undefined, Validators.required],
          insuranceContractType: [
            '' as string | null | undefined,
            Validators.required,
          ],
        }),
        verificationCode: ['', Validators.required],
        token: [{ value: '', disabled: true }, Validators.required],
      }) as NumberVerificationForm,
      step2: this.nnfb.group({
        claimAffectedPerson: this.nnfb.group({
          gender: [
            undefined as ClaimApi.Gender | undefined,
            Validators.required,
          ],
          firstName: ['', Validators.required],
          lastName: ['', Validators.required],
          addressLine1: ['', Validators.required],
          postalCode: ['', Validators.required],
          city: ['', Validators.required],
          dateOfBirth: [
            '',
            [
              Validators.required,
              notInTheFutureValidator(),
              dateNotExistingValidator(),
            ],
          ],
          nationalIdentificationNumber: ['', Validators.required],
          email: ['', [Validators.required, Validators.email]],
          phoneNumber: ['', Validators.required],
          type: [undefined as ClaimApi.ClaimAffectedPersonType | undefined],
        }),
        contractHolder: [undefined as ClaimApi.ContractHolderDto | undefined],
      }) as PersonalDataForm,
      step3: this.nnfb.group({
        name: [''],
        content: [''],
        mimeType: [''],
      }),
    },
  );

  protected readonly steps = [
    {
      componentClass: FullStepVerifyNumberComponent,
      componentInputs: {
        stepControl: this.form.controls.step1,
        countryPrefixActivated: environment.countryPrefixActivated,
      },
    },
    {
      componentClass: FullStepPersonalDataComponent,
      componentInputs: {
        stepControl: this.form.controls.step2,
      },
    },
    {
      componentClass: LiabilityEndpageComponent,
      componentInputs: {
        stepControl: this.form.controls.step2,
      },
    },
  ];

  constructor() {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use('en');
  }

  fillForm() {
    this.form.patchValue(kevin);
  }
}

const kevin = {
  step2: {
    claimAffectedPerson: {
      gender: 'Female' as ClaimApi.Gender,
      firstName: 'Jane',
      lastName: 'Doe',
      addressLine1: 'Muster Street 17',
      postalCode: '1234',
      city: 'TestCity',
      dateOfBirth: '1995-12-17',
      nationalIdentificationNumber: '1234',
      email: 'jane.doe@gmail.com',
      phoneNumber: '+436644444444',
      type: 'ContractUser' as ClaimApi.ClaimAffectedPersonType,
    },
    contractHolder: {
      gender: 'Male' as ClaimApi.Gender,
      firstName: 'joe',
      lastName: 'ContractHolder',
      addressLine1: 'Muster Street 17',
      postalCode: '1234',
      city: 'TestCity',
      dateOfBirth: '1995-12-17',
      nationalIdentificationNumber: '1234',
    },
  },
};
