/**
 * Policy Services API
 * The Policy Services API provides methods for activating new as well as managing and canceling existing insurance contracts.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PersonType = 'Child' | 'Adult';

export const PersonType = {
    Child: 'Child' as PersonType,
    Adult: 'Adult' as PersonType
};

