import { HttpClient } from '@angular/common/http';
import { Injectable, InjectionToken, inject } from '@angular/core';
import { Observable } from 'rxjs';

const AUTH_PATH = '/auth/realms/core/protocol/openid-connect/token';

const AUTH_BODY = {
  grant_type: 'client_credentials',
  scope: 'openid',
  client_id: 'claim-registration-a1svn',
  client_secret: 'bSdrQXV61gtdgSAJTSXjtPcW3TOxnaJ6',
};

type LamieAuthResponse = {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: number;
  token_type: 'Bearer';
  'not-before-policy': number;
  session_state: string;
  scope: string;
};

export const AUTH_API_BASE_URL = new InjectionToken<string>(
  'AUTH_API_BASE_URL'
);

@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly authApiBaseUrl = inject(AUTH_API_BASE_URL);
  private readonly httpClient = inject(HttpClient);

  // private authResponse?: LamieAuthResponse;

  // getAccessToken(): string {
  //   const accessToken = this.authResponse?.access_token;
  //   if (!accessToken) throw new Error('no token!');
  //   return accessToken;
  // }

  get authUrl(): string {
    return `${this.authApiBaseUrl}${AUTH_PATH}`;
  }

  getAuth(): Observable<LamieAuthResponse> {
    return this.httpClient.post<LamieAuthResponse>(
      this.authUrl,
      new URLSearchParams(AUTH_BODY),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    // .pipe(
    //   tap((response) => {
    //     this.authResponse = response;
    //   })
    // );
  }
}
