/**
 * Policy Services API
 * The Policy Services API provides methods for activating new as well as managing and canceling existing insurance contracts.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { CancelByMsisdnDto } from '../model/cancelByMsisdnDto';
// @ts-ignore
import { CancelByPolicyNumberDto } from '../model/cancelByPolicyNumberDto';
// @ts-ignore
import { CancelationResponseApiResponse } from '../model/cancelationResponseApiResponse';
// @ts-ignore
import { ChangeCoverByMsisdnDto } from '../model/changeCoverByMsisdnDto';
// @ts-ignore
import { ChangeCoverByPolicyNumberDto } from '../model/changeCoverByPolicyNumberDto';
// @ts-ignore
import { CoreSystemFileApiResponse } from '../model/coreSystemFileApiResponse';
// @ts-ignore
import { DeletePromotionCodeByMsisdnDto } from '../model/deletePromotionCodeByMsisdnDto';
// @ts-ignore
import { EmptyResultApiResponse } from '../model/emptyResultApiResponse';
// @ts-ignore
import { ErrorResponse } from '../model/errorResponse';
// @ts-ignore
import { HasActiveContractResponseApiResponse } from '../model/hasActiveContractResponseApiResponse';
// @ts-ignore
import { InsuranceConfirmationRequestDto } from '../model/insuranceConfirmationRequestDto';
// @ts-ignore
import { JwTokenInfo } from '../model/jwTokenInfo';
// @ts-ignore
import { NotifyAddressChangeDto } from '../model/notifyAddressChangeDto';
// @ts-ignore
import { PolicyCreationDto } from '../model/policyCreationDto';
// @ts-ignore
import { PolicyResponseDtoApiResponse } from '../model/policyResponseDtoApiResponse';
// @ts-ignore
import { UpdateContractStatusDto } from '../model/updateContractStatusDto';
// @ts-ignore
import { UpdateMsisdnByMsisdnDto } from '../model/updateMsisdnByMsisdnDto';
// @ts-ignore
import { UpdateMsisdnByPolicyNumberDto } from '../model/updateMsisdnByPolicyNumberDto';
// @ts-ignore
import { UpdatePromotionCodeByMsisdnDto } from '../model/updatePromotionCodeByMsisdnDto';
// @ts-ignore
import { UpdateTariffByMsisdnDto } from '../model/updateTariffByMsisdnDto';
// @ts-ignore
import { UpdateTariffByPolicyNumberDto } from '../model/updateTariffByPolicyNumberDto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface PolicyApiV1PoliciesCancelbymsisdnPutRequestParams {
    /** Unique correlationId to identify partner requests throughout all called services */
    correlationId: string;
    /** Id to identify from which user the requests is comming from. External partners use the provided partner key */
    userPrincipalId: string;
    /** Name of the product category, for which the insurance contract shall be created (e.g. \&quot;TravelInsurance\&quot;) */
    product?: string;
    /**  */
    cancelByMsisdnDto?: CancelByMsisdnDto;
}

export interface PolicyApiV1PoliciesCancelbypolicynumberPutRequestParams {
    /** Unique correlationId to identify partner requests throughout all called services */
    correlationId: string;
    /** Id to identify from which user the requests is comming from. External partners use the provided partner key */
    userPrincipalId: string;
    /** Name of the product category, for which the insurance contract shall be created (e.g. \&quot;TravelInsurance\&quot;) */
    product?: string;
    /**  */
    cancelByPolicyNumberDto?: CancelByPolicyNumberDto;
}

export interface PolicyApiV1PoliciesChangecoverbymsisdnPutRequestParams {
    /** Unique correlationId to identify partner requests throughout all called services */
    correlationId: string;
    /** Id to identify from which user the requests is comming from. External partners use the provided partner key */
    userPrincipalId: string;
    /** Name of the product category, e.g. \&quot;TravelInsurance\&quot; */
    product?: string;
    /**  */
    changeCoverByMsisdnDto?: ChangeCoverByMsisdnDto;
}

export interface PolicyApiV1PoliciesChangecoverbypolicynumberPutRequestParams {
    /** Unique correlationId to identify partner requests throughout all called services */
    correlationId: string;
    /** Id to identify from which user the requests is comming from. External partners use the provided partner key */
    userPrincipalId: string;
    /** Name of the product category, e.g. \&quot;TravelInsurance\&quot; */
    product?: string;
    /**  */
    changeCoverByPolicyNumberDto?: ChangeCoverByPolicyNumberDto;
}

export interface PolicyApiV1PoliciesCreatePostRequestParams {
    /** Unique correlationId to identify partner requests throughout all called services */
    correlationId: string;
    /** Id to identify from which user the requests is comming from. External partners use the provided partner key */
    userPrincipalId: string;
    /** Name of the product category, for which the insurance contract shall be created (e.g. \&quot;TravelInsurance\&quot;) */
    product?: string;
    /** Proposal ID */
    proposalId?: string;
    /**  */
    policyCreationDto?: PolicyCreationDto;
}

export interface PolicyApiV1PoliciesDeletepromotioncodebymsisdnDeleteRequestParams {
    /** Unique correlationId to identify partner requests throughout all called services */
    correlationId: string;
    /** Id to identify from which user the requests is comming from. External partners use the provided partner key */
    userPrincipalId: string;
    /** Name of the product category, e.g. \&quot;TravelInsurance\&quot; */
    product?: string;
    /**  */
    deletePromotionCodeByMsisdnDto?: DeletePromotionCodeByMsisdnDto;
}

export interface PolicyApiV1PoliciesGenerateinsuranceconfirmationPostRequestParams {
    /** Unique correlationId to identify partner requests throughout all called services */
    correlationId: string;
    /** Id to identify from which user the requests is comming from. External partners use the provided partner key */
    userPrincipalId: string;
    /** Unique subscription-key to identify user */
    subscriptionKey: string;
    product?: string;
    insuranceConfirmationRequestDto?: InsuranceConfirmationRequestDto;
}

export interface PolicyApiV1PoliciesGetcustomerdatafrompartnerPostRequestParams {
    /** Unique correlationId to identify partner requests throughout all called services */
    correlationId: string;
    /** Id to identify from which user the requests is comming from. External partners use the provided partner key */
    userPrincipalId: string;
    /** product categroy e.g. TravelInsurance */
    product?: string;
    /** uniquely identifies the contract which its MSISDN */
    msisdn?: string;
    /** JWToken information */
    jwTokenInfo?: JwTokenInfo;
}

export interface PolicyApiV1PoliciesHasactivecontractGetRequestParams {
    /** Unique correlationId to identify partner requests throughout all called services */
    correlationId: string;
    /** Id to identify from which user the requests is comming from. External partners use the provided partner key */
    userPrincipalId: string;
    /** product category e.g. TravelInsurance */
    product?: string;
    /** uniquely identifies the contract which its MSISDN */
    msisdn?: string;
}

export interface PolicyApiV1PoliciesIstariffeligibleGetRequestParams {
    /** Unique correlationId to identify partner requests throughout all called services */
    correlationId: string;
    /** Id to identify from which user the requests is comming from. External partners use the provided partner key */
    userPrincipalId: string;
    /** product categroy e.g. TravelInsurance */
    product?: string;
    /** uniquely identifies the contract which its MSISDN */
    msisdn?: string;
}

export interface PolicyApiV1PoliciesNotifyaddresschangePatchRequestParams {
    /** Unique correlationId to identify partner requests throughout all called services */
    correlationId: string;
    /** Id to identify from which user the requests is comming from. External partners use the provided partner key */
    userPrincipalId: string;
    product?: string;
    notifyAddressChangeDto?: NotifyAddressChangeDto;
}

export interface PolicyApiV1PoliciesUpdatemsisdnbymsisdnPutRequestParams {
    /** Unique correlationId to identify partner requests throughout all called services */
    correlationId: string;
    /** Id to identify from which user the requests is comming from. External partners use the provided partner key */
    userPrincipalId: string;
    /** Name of the product category, e.g. \&quot;TravelInsurance\&quot; */
    product?: string;
    /**  */
    updateMsisdnByMsisdnDto?: UpdateMsisdnByMsisdnDto;
}

export interface PolicyApiV1PoliciesUpdatemsisdnbypolicynumberPutRequestParams {
    /** Unique correlationId to identify partner requests throughout all called services */
    correlationId: string;
    /** Id to identify from which user the requests is comming from. External partners use the provided partner key */
    userPrincipalId: string;
    /** Name of the product category, for which the insurance contract shall be created (e.g. \&quot;TravelInsurance\&quot;) */
    product?: string;
    /**  */
    updateMsisdnByPolicyNumberDto?: UpdateMsisdnByPolicyNumberDto;
}

export interface PolicyApiV1PoliciesUpdatepromotioncodebymsisdnPatchRequestParams {
    /** Unique correlationId to identify partner requests throughout all called services */
    correlationId: string;
    /** Id to identify from which user the requests is comming from. External partners use the provided partner key */
    userPrincipalId: string;
    /** Name of the product category, e.g. \&quot;TravelInsurance\&quot; */
    product?: string;
    /**  */
    updatePromotionCodeByMsisdnDto?: UpdatePromotionCodeByMsisdnDto;
}

export interface PolicyApiV1PoliciesUpdatestatusPutRequestParams {
    /** Unique correlationId to identify partner requests throughout all called services */
    correlationId: string;
    /** Id to identify from which user the requests is comming from. External partners use the provided partner key */
    userPrincipalId: string;
    /** product categroy e.g. TravelInsurance */
    product?: string;
    /**  */
    updateContractStatusDto?: UpdateContractStatusDto;
}

export interface PolicyApiV1PoliciesUpdatetariffbymsisdnPutRequestParams {
    /** Unique correlationId to identify partner requests throughout all called services */
    correlationId: string;
    /** Id to identify from which user the requests is comming from. External partners use the provided partner key */
    userPrincipalId: string;
    /** Name of the product category, e.g. \&quot;TravelInsurance\&quot; */
    product?: string;
    /**  */
    updateTariffByMsisdnDto?: UpdateTariffByMsisdnDto;
}

export interface PolicyApiV1PoliciesUpdatetariffbypolicynumberPutRequestParams {
    /** Unique correlationId to identify partner requests throughout all called services */
    correlationId: string;
    /** Id to identify from which user the requests is comming from. External partners use the provided partner key */
    userPrincipalId: string;
    /** Name of the product category, e.g. \&quot;TravelInsurance\&quot; */
    product?: string;
    /**  */
    updateTariffByPolicyNumberDto?: UpdateTariffByPolicyNumberDto;
}


@Injectable({
  providedIn: 'root'
})
export class PolicyService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Cancel insurance contract by its MSISDN
     * Cancels the insurance contract at LAMIE. Possible reasons are, that the underlying base contract at the partner (e.g. mobile phone contract) has been canceled, or the customer canceled the insurance contract online on a website or customer portal.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyApiV1PoliciesCancelbymsisdnPut(requestParameters: PolicyApiV1PoliciesCancelbymsisdnPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<CancelationResponseApiResponse>;
    public policyApiV1PoliciesCancelbymsisdnPut(requestParameters: PolicyApiV1PoliciesCancelbymsisdnPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<CancelationResponseApiResponse>>;
    public policyApiV1PoliciesCancelbymsisdnPut(requestParameters: PolicyApiV1PoliciesCancelbymsisdnPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<CancelationResponseApiResponse>>;
    public policyApiV1PoliciesCancelbymsisdnPut(requestParameters: PolicyApiV1PoliciesCancelbymsisdnPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const correlationId = requestParameters.correlationId;
        if (correlationId === null || correlationId === undefined) {
            throw new Error('Required parameter correlationId was null or undefined when calling policyApiV1PoliciesCancelbymsisdnPut.');
        }
        const userPrincipalId = requestParameters.userPrincipalId;
        if (userPrincipalId === null || userPrincipalId === undefined) {
            throw new Error('Required parameter userPrincipalId was null or undefined when calling policyApiV1PoliciesCancelbymsisdnPut.');
        }
        const product = requestParameters.product;
        const cancelByMsisdnDto = requestParameters.cancelByMsisdnDto;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'Product');
        }

        let localVarHeaders = this.defaultHeaders;
        if (correlationId !== undefined && correlationId !== null) {
            localVarHeaders = localVarHeaders.set('CorrelationId', String(correlationId));
        }
        if (userPrincipalId !== undefined && userPrincipalId !== null) {
            localVarHeaders = localVarHeaders.set('UserPrincipalId', String(userPrincipalId));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/policy/api/v1/policies/cancelbymsisdn`;
        return this.httpClient.request<CancelationResponseApiResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: cancelByMsisdnDto,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Cancel insurance contract by its policy number
     * Cancels the insurance contract at LAMIE. Possible reasons are, that the underlying base contract at the partner (e.g. mobile phone contract) has been canceled, or the customer canceled the insurance contract online on a website or customer portal.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyApiV1PoliciesCancelbypolicynumberPut(requestParameters: PolicyApiV1PoliciesCancelbypolicynumberPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<CancelationResponseApiResponse>;
    public policyApiV1PoliciesCancelbypolicynumberPut(requestParameters: PolicyApiV1PoliciesCancelbypolicynumberPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<CancelationResponseApiResponse>>;
    public policyApiV1PoliciesCancelbypolicynumberPut(requestParameters: PolicyApiV1PoliciesCancelbypolicynumberPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<CancelationResponseApiResponse>>;
    public policyApiV1PoliciesCancelbypolicynumberPut(requestParameters: PolicyApiV1PoliciesCancelbypolicynumberPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const correlationId = requestParameters.correlationId;
        if (correlationId === null || correlationId === undefined) {
            throw new Error('Required parameter correlationId was null or undefined when calling policyApiV1PoliciesCancelbypolicynumberPut.');
        }
        const userPrincipalId = requestParameters.userPrincipalId;
        if (userPrincipalId === null || userPrincipalId === undefined) {
            throw new Error('Required parameter userPrincipalId was null or undefined when calling policyApiV1PoliciesCancelbypolicynumberPut.');
        }
        const product = requestParameters.product;
        const cancelByPolicyNumberDto = requestParameters.cancelByPolicyNumberDto;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }

        let localVarHeaders = this.defaultHeaders;
        if (correlationId !== undefined && correlationId !== null) {
            localVarHeaders = localVarHeaders.set('CorrelationId', String(correlationId));
        }
        if (userPrincipalId !== undefined && userPrincipalId !== null) {
            localVarHeaders = localVarHeaders.set('UserPrincipalId', String(userPrincipalId));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/policy/api/v1/policies/cancelbypolicynumber`;
        return this.httpClient.request<CancelationResponseApiResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: cancelByPolicyNumberDto,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change insurance cover option by MSISDN.
     * This method changes the insurance cover option (e.g. Single/Family or Basic/Premium) of the contract identified by its MSISDN. An endorsement policy document is generated and sent to the customer by email.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyApiV1PoliciesChangecoverbymsisdnPut(requestParameters: PolicyApiV1PoliciesChangecoverbymsisdnPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<EmptyResultApiResponse>;
    public policyApiV1PoliciesChangecoverbymsisdnPut(requestParameters: PolicyApiV1PoliciesChangecoverbymsisdnPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<EmptyResultApiResponse>>;
    public policyApiV1PoliciesChangecoverbymsisdnPut(requestParameters: PolicyApiV1PoliciesChangecoverbymsisdnPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<EmptyResultApiResponse>>;
    public policyApiV1PoliciesChangecoverbymsisdnPut(requestParameters: PolicyApiV1PoliciesChangecoverbymsisdnPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const correlationId = requestParameters.correlationId;
        if (correlationId === null || correlationId === undefined) {
            throw new Error('Required parameter correlationId was null or undefined when calling policyApiV1PoliciesChangecoverbymsisdnPut.');
        }
        const userPrincipalId = requestParameters.userPrincipalId;
        if (userPrincipalId === null || userPrincipalId === undefined) {
            throw new Error('Required parameter userPrincipalId was null or undefined when calling policyApiV1PoliciesChangecoverbymsisdnPut.');
        }
        const product = requestParameters.product;
        const changeCoverByMsisdnDto = requestParameters.changeCoverByMsisdnDto;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }

        let localVarHeaders = this.defaultHeaders;
        if (correlationId !== undefined && correlationId !== null) {
            localVarHeaders = localVarHeaders.set('CorrelationId', String(correlationId));
        }
        if (userPrincipalId !== undefined && userPrincipalId !== null) {
            localVarHeaders = localVarHeaders.set('UserPrincipalId', String(userPrincipalId));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/policy/api/v1/policies/changecoverbymsisdn`;
        return this.httpClient.request<EmptyResultApiResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: changeCoverByMsisdnDto,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change insurance cover option by policy number.
     * This method changes the insurance cover option (e.g. Single/Family or Basic/Premium) of the contract identified by its policy number. An endorsement policy document is generated and sent to the customer by email.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyApiV1PoliciesChangecoverbypolicynumberPut(requestParameters: PolicyApiV1PoliciesChangecoverbypolicynumberPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<EmptyResultApiResponse>;
    public policyApiV1PoliciesChangecoverbypolicynumberPut(requestParameters: PolicyApiV1PoliciesChangecoverbypolicynumberPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<EmptyResultApiResponse>>;
    public policyApiV1PoliciesChangecoverbypolicynumberPut(requestParameters: PolicyApiV1PoliciesChangecoverbypolicynumberPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<EmptyResultApiResponse>>;
    public policyApiV1PoliciesChangecoverbypolicynumberPut(requestParameters: PolicyApiV1PoliciesChangecoverbypolicynumberPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const correlationId = requestParameters.correlationId;
        if (correlationId === null || correlationId === undefined) {
            throw new Error('Required parameter correlationId was null or undefined when calling policyApiV1PoliciesChangecoverbypolicynumberPut.');
        }
        const userPrincipalId = requestParameters.userPrincipalId;
        if (userPrincipalId === null || userPrincipalId === undefined) {
            throw new Error('Required parameter userPrincipalId was null or undefined when calling policyApiV1PoliciesChangecoverbypolicynumberPut.');
        }
        const product = requestParameters.product;
        const changeCoverByPolicyNumberDto = requestParameters.changeCoverByPolicyNumberDto;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }

        let localVarHeaders = this.defaultHeaders;
        if (correlationId !== undefined && correlationId !== null) {
            localVarHeaders = localVarHeaders.set('CorrelationId', String(correlationId));
        }
        if (userPrincipalId !== undefined && userPrincipalId !== null) {
            localVarHeaders = localVarHeaders.set('UserPrincipalId', String(userPrincipalId));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/policy/api/v1/policies/changecoverbypolicynumber`;
        return this.httpClient.request<EmptyResultApiResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: changeCoverByPolicyNumberDto,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create new insurance contract
     * The method creates a new insurance contract, which has been concluded online, in the LAMIE system and sends the policy document to the customer by email.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyApiV1PoliciesCreatePost(requestParameters: PolicyApiV1PoliciesCreatePostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PolicyResponseDtoApiResponse>;
    public policyApiV1PoliciesCreatePost(requestParameters: PolicyApiV1PoliciesCreatePostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PolicyResponseDtoApiResponse>>;
    public policyApiV1PoliciesCreatePost(requestParameters: PolicyApiV1PoliciesCreatePostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PolicyResponseDtoApiResponse>>;
    public policyApiV1PoliciesCreatePost(requestParameters: PolicyApiV1PoliciesCreatePostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const correlationId = requestParameters.correlationId;
        if (correlationId === null || correlationId === undefined) {
            throw new Error('Required parameter correlationId was null or undefined when calling policyApiV1PoliciesCreatePost.');
        }
        const userPrincipalId = requestParameters.userPrincipalId;
        if (userPrincipalId === null || userPrincipalId === undefined) {
            throw new Error('Required parameter userPrincipalId was null or undefined when calling policyApiV1PoliciesCreatePost.');
        }
        const product = requestParameters.product;
        const proposalId = requestParameters.proposalId;
        const policyCreationDto = requestParameters.policyCreationDto;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }
        if (proposalId !== undefined && proposalId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>proposalId, 'proposalId');
        }

        let localVarHeaders = this.defaultHeaders;
        if (correlationId !== undefined && correlationId !== null) {
            localVarHeaders = localVarHeaders.set('CorrelationId', String(correlationId));
        }
        if (userPrincipalId !== undefined && userPrincipalId !== null) {
            localVarHeaders = localVarHeaders.set('UserPrincipalId', String(userPrincipalId));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/policy/api/v1/policies/create`;
        return this.httpClient.request<PolicyResponseDtoApiResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: policyCreationDto,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete promotion code in a contract by MSISDN.
     * This method deletes a promotion code in a contract identified by its MSISDN.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyApiV1PoliciesDeletepromotioncodebymsisdnDelete(requestParameters: PolicyApiV1PoliciesDeletepromotioncodebymsisdnDeleteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<EmptyResultApiResponse>;
    public policyApiV1PoliciesDeletepromotioncodebymsisdnDelete(requestParameters: PolicyApiV1PoliciesDeletepromotioncodebymsisdnDeleteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<EmptyResultApiResponse>>;
    public policyApiV1PoliciesDeletepromotioncodebymsisdnDelete(requestParameters: PolicyApiV1PoliciesDeletepromotioncodebymsisdnDeleteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<EmptyResultApiResponse>>;
    public policyApiV1PoliciesDeletepromotioncodebymsisdnDelete(requestParameters: PolicyApiV1PoliciesDeletepromotioncodebymsisdnDeleteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const correlationId = requestParameters.correlationId;
        if (correlationId === null || correlationId === undefined) {
            throw new Error('Required parameter correlationId was null or undefined when calling policyApiV1PoliciesDeletepromotioncodebymsisdnDelete.');
        }
        const userPrincipalId = requestParameters.userPrincipalId;
        if (userPrincipalId === null || userPrincipalId === undefined) {
            throw new Error('Required parameter userPrincipalId was null or undefined when calling policyApiV1PoliciesDeletepromotioncodebymsisdnDelete.');
        }
        const product = requestParameters.product;
        const deletePromotionCodeByMsisdnDto = requestParameters.deletePromotionCodeByMsisdnDto;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }

        let localVarHeaders = this.defaultHeaders;
        if (correlationId !== undefined && correlationId !== null) {
            localVarHeaders = localVarHeaders.set('CorrelationId', String(correlationId));
        }
        if (userPrincipalId !== undefined && userPrincipalId !== null) {
            localVarHeaders = localVarHeaders.set('UserPrincipalId', String(userPrincipalId));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/policy/api/v1/policies/deletepromotioncodebymsisdn`;
        return this.httpClient.request<EmptyResultApiResponse>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: deletePromotionCodeByMsisdnDto,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyApiV1PoliciesGenerateinsuranceconfirmationPost(requestParameters: PolicyApiV1PoliciesGenerateinsuranceconfirmationPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<CoreSystemFileApiResponse>;
    public policyApiV1PoliciesGenerateinsuranceconfirmationPost(requestParameters: PolicyApiV1PoliciesGenerateinsuranceconfirmationPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<CoreSystemFileApiResponse>>;
    public policyApiV1PoliciesGenerateinsuranceconfirmationPost(requestParameters: PolicyApiV1PoliciesGenerateinsuranceconfirmationPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<CoreSystemFileApiResponse>>;
    public policyApiV1PoliciesGenerateinsuranceconfirmationPost(requestParameters: PolicyApiV1PoliciesGenerateinsuranceconfirmationPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const correlationId = requestParameters.correlationId;
        if (correlationId === null || correlationId === undefined) {
            throw new Error('Required parameter correlationId was null or undefined when calling policyApiV1PoliciesGenerateinsuranceconfirmationPost.');
        }
        const userPrincipalId = requestParameters.userPrincipalId;
        if (userPrincipalId === null || userPrincipalId === undefined) {
            throw new Error('Required parameter userPrincipalId was null or undefined when calling policyApiV1PoliciesGenerateinsuranceconfirmationPost.');
        }
        const subscriptionKey = requestParameters.subscriptionKey;
        if (subscriptionKey === null || subscriptionKey === undefined) {
            throw new Error('Required parameter subscriptionKey was null or undefined when calling policyApiV1PoliciesGenerateinsuranceconfirmationPost.');
        }
        const product = requestParameters.product;
        const insuranceConfirmationRequestDto = requestParameters.insuranceConfirmationRequestDto;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }

        let localVarHeaders = this.defaultHeaders;
        if (correlationId !== undefined && correlationId !== null) {
            localVarHeaders = localVarHeaders.set('CorrelationId', String(correlationId));
        }
        if (userPrincipalId !== undefined && userPrincipalId !== null) {
            localVarHeaders = localVarHeaders.set('UserPrincipalId', String(userPrincipalId));
        }
        if (subscriptionKey !== undefined && subscriptionKey !== null) {
            localVarHeaders = localVarHeaders.set('subscription-key', String(subscriptionKey));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/policy/api/v1/policies/generateinsuranceconfirmation`;
        return this.httpClient.request<CoreSystemFileApiResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: insuranceConfirmationRequestDto,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieving customer data by calling a partner API (using jwt)
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyApiV1PoliciesGetcustomerdatafrompartnerPost(requestParameters: PolicyApiV1PoliciesGetcustomerdatafrompartnerPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public policyApiV1PoliciesGetcustomerdatafrompartnerPost(requestParameters: PolicyApiV1PoliciesGetcustomerdatafrompartnerPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public policyApiV1PoliciesGetcustomerdatafrompartnerPost(requestParameters: PolicyApiV1PoliciesGetcustomerdatafrompartnerPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public policyApiV1PoliciesGetcustomerdatafrompartnerPost(requestParameters: PolicyApiV1PoliciesGetcustomerdatafrompartnerPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const correlationId = requestParameters.correlationId;
        if (correlationId === null || correlationId === undefined) {
            throw new Error('Required parameter correlationId was null or undefined when calling policyApiV1PoliciesGetcustomerdatafrompartnerPost.');
        }
        const userPrincipalId = requestParameters.userPrincipalId;
        if (userPrincipalId === null || userPrincipalId === undefined) {
            throw new Error('Required parameter userPrincipalId was null or undefined when calling policyApiV1PoliciesGetcustomerdatafrompartnerPost.');
        }
        const product = requestParameters.product;
        const msisdn = requestParameters.msisdn;
        const jwTokenInfo = requestParameters.jwTokenInfo;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }
        if (msisdn !== undefined && msisdn !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>msisdn, 'msisdn');
        }

        let localVarHeaders = this.defaultHeaders;
        if (correlationId !== undefined && correlationId !== null) {
            localVarHeaders = localVarHeaders.set('CorrelationId', String(correlationId));
        }
        if (userPrincipalId !== undefined && userPrincipalId !== null) {
            localVarHeaders = localVarHeaders.set('UserPrincipalId', String(userPrincipalId));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/policy/api/v1/policies/getcustomerdatafrompartner`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: jwTokenInfo,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Checks if the a contract with given product and msisdn exists or is active (e.g. true/false)
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyApiV1PoliciesHasactivecontractGet(requestParameters: PolicyApiV1PoliciesHasactivecontractGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HasActiveContractResponseApiResponse>;
    public policyApiV1PoliciesHasactivecontractGet(requestParameters: PolicyApiV1PoliciesHasactivecontractGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<HasActiveContractResponseApiResponse>>;
    public policyApiV1PoliciesHasactivecontractGet(requestParameters: PolicyApiV1PoliciesHasactivecontractGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<HasActiveContractResponseApiResponse>>;
    public policyApiV1PoliciesHasactivecontractGet(requestParameters: PolicyApiV1PoliciesHasactivecontractGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const correlationId = requestParameters.correlationId;
        if (correlationId === null || correlationId === undefined) {
            throw new Error('Required parameter correlationId was null or undefined when calling policyApiV1PoliciesHasactivecontractGet.');
        }
        const userPrincipalId = requestParameters.userPrincipalId;
        if (userPrincipalId === null || userPrincipalId === undefined) {
            throw new Error('Required parameter userPrincipalId was null or undefined when calling policyApiV1PoliciesHasactivecontractGet.');
        }
        const product = requestParameters.product;
        const msisdn = requestParameters.msisdn;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }
        if (msisdn !== undefined && msisdn !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>msisdn, 'msisdn');
        }

        let localVarHeaders = this.defaultHeaders;
        if (correlationId !== undefined && correlationId !== null) {
            localVarHeaders = localVarHeaders.set('CorrelationId', String(correlationId));
        }
        if (userPrincipalId !== undefined && userPrincipalId !== null) {
            localVarHeaders = localVarHeaders.set('UserPrincipalId', String(userPrincipalId));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/policy/api/v1/policies/hasactivecontract`;
        return this.httpClient.request<HasActiveContractResponseApiResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Validate tariff of customer with given MSISDN by calling a partner API
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyApiV1PoliciesIstariffeligibleGet(requestParameters: PolicyApiV1PoliciesIstariffeligibleGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public policyApiV1PoliciesIstariffeligibleGet(requestParameters: PolicyApiV1PoliciesIstariffeligibleGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public policyApiV1PoliciesIstariffeligibleGet(requestParameters: PolicyApiV1PoliciesIstariffeligibleGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public policyApiV1PoliciesIstariffeligibleGet(requestParameters: PolicyApiV1PoliciesIstariffeligibleGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const correlationId = requestParameters.correlationId;
        if (correlationId === null || correlationId === undefined) {
            throw new Error('Required parameter correlationId was null or undefined when calling policyApiV1PoliciesIstariffeligibleGet.');
        }
        const userPrincipalId = requestParameters.userPrincipalId;
        if (userPrincipalId === null || userPrincipalId === undefined) {
            throw new Error('Required parameter userPrincipalId was null or undefined when calling policyApiV1PoliciesIstariffeligibleGet.');
        }
        const product = requestParameters.product;
        const msisdn = requestParameters.msisdn;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }
        if (msisdn !== undefined && msisdn !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>msisdn, 'msisdn');
        }

        let localVarHeaders = this.defaultHeaders;
        if (correlationId !== undefined && correlationId !== null) {
            localVarHeaders = localVarHeaders.set('CorrelationId', String(correlationId));
        }
        if (userPrincipalId !== undefined && userPrincipalId !== null) {
            localVarHeaders = localVarHeaders.set('UserPrincipalId', String(userPrincipalId));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/policy/api/v1/policies/istariffeligible`;
        return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Notify the user if a customer\&#39;s address has been changed.
     * This method notifies the user if a customer\&#39;s address has been changed.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyApiV1PoliciesNotifyaddresschangePatch(requestParameters: PolicyApiV1PoliciesNotifyaddresschangePatchRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<EmptyResultApiResponse>;
    public policyApiV1PoliciesNotifyaddresschangePatch(requestParameters: PolicyApiV1PoliciesNotifyaddresschangePatchRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<EmptyResultApiResponse>>;
    public policyApiV1PoliciesNotifyaddresschangePatch(requestParameters: PolicyApiV1PoliciesNotifyaddresschangePatchRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<EmptyResultApiResponse>>;
    public policyApiV1PoliciesNotifyaddresschangePatch(requestParameters: PolicyApiV1PoliciesNotifyaddresschangePatchRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const correlationId = requestParameters.correlationId;
        if (correlationId === null || correlationId === undefined) {
            throw new Error('Required parameter correlationId was null or undefined when calling policyApiV1PoliciesNotifyaddresschangePatch.');
        }
        const userPrincipalId = requestParameters.userPrincipalId;
        if (userPrincipalId === null || userPrincipalId === undefined) {
            throw new Error('Required parameter userPrincipalId was null or undefined when calling policyApiV1PoliciesNotifyaddresschangePatch.');
        }
        const product = requestParameters.product;
        const notifyAddressChangeDto = requestParameters.notifyAddressChangeDto;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }

        let localVarHeaders = this.defaultHeaders;
        if (correlationId !== undefined && correlationId !== null) {
            localVarHeaders = localVarHeaders.set('CorrelationId', String(correlationId));
        }
        if (userPrincipalId !== undefined && userPrincipalId !== null) {
            localVarHeaders = localVarHeaders.set('UserPrincipalId', String(userPrincipalId));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/policy/api/v1/policies/notifyaddresschange`;
        return this.httpClient.request<EmptyResultApiResponse>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: notifyAddressChangeDto,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update MSISDN in insurance contract by its current MSISDN.
     * This method updates the phone number (MSISDN) of the contract identified by the currently stored (former) MSISDN.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyApiV1PoliciesUpdatemsisdnbymsisdnPut(requestParameters: PolicyApiV1PoliciesUpdatemsisdnbymsisdnPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<EmptyResultApiResponse>;
    public policyApiV1PoliciesUpdatemsisdnbymsisdnPut(requestParameters: PolicyApiV1PoliciesUpdatemsisdnbymsisdnPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<EmptyResultApiResponse>>;
    public policyApiV1PoliciesUpdatemsisdnbymsisdnPut(requestParameters: PolicyApiV1PoliciesUpdatemsisdnbymsisdnPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<EmptyResultApiResponse>>;
    public policyApiV1PoliciesUpdatemsisdnbymsisdnPut(requestParameters: PolicyApiV1PoliciesUpdatemsisdnbymsisdnPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const correlationId = requestParameters.correlationId;
        if (correlationId === null || correlationId === undefined) {
            throw new Error('Required parameter correlationId was null or undefined when calling policyApiV1PoliciesUpdatemsisdnbymsisdnPut.');
        }
        const userPrincipalId = requestParameters.userPrincipalId;
        if (userPrincipalId === null || userPrincipalId === undefined) {
            throw new Error('Required parameter userPrincipalId was null or undefined when calling policyApiV1PoliciesUpdatemsisdnbymsisdnPut.');
        }
        const product = requestParameters.product;
        const updateMsisdnByMsisdnDto = requestParameters.updateMsisdnByMsisdnDto;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }

        let localVarHeaders = this.defaultHeaders;
        if (correlationId !== undefined && correlationId !== null) {
            localVarHeaders = localVarHeaders.set('CorrelationId', String(correlationId));
        }
        if (userPrincipalId !== undefined && userPrincipalId !== null) {
            localVarHeaders = localVarHeaders.set('UserPrincipalId', String(userPrincipalId));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/policy/api/v1/policies/updatemsisdnbymsisdn`;
        return this.httpClient.request<EmptyResultApiResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateMsisdnByMsisdnDto,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update MSISDN in insurance contract by its policy number.
     * This method updates the phone number (MSISDN) of the contract identified by its policy number.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyApiV1PoliciesUpdatemsisdnbypolicynumberPut(requestParameters: PolicyApiV1PoliciesUpdatemsisdnbypolicynumberPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<EmptyResultApiResponse>;
    public policyApiV1PoliciesUpdatemsisdnbypolicynumberPut(requestParameters: PolicyApiV1PoliciesUpdatemsisdnbypolicynumberPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<EmptyResultApiResponse>>;
    public policyApiV1PoliciesUpdatemsisdnbypolicynumberPut(requestParameters: PolicyApiV1PoliciesUpdatemsisdnbypolicynumberPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<EmptyResultApiResponse>>;
    public policyApiV1PoliciesUpdatemsisdnbypolicynumberPut(requestParameters: PolicyApiV1PoliciesUpdatemsisdnbypolicynumberPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const correlationId = requestParameters.correlationId;
        if (correlationId === null || correlationId === undefined) {
            throw new Error('Required parameter correlationId was null or undefined when calling policyApiV1PoliciesUpdatemsisdnbypolicynumberPut.');
        }
        const userPrincipalId = requestParameters.userPrincipalId;
        if (userPrincipalId === null || userPrincipalId === undefined) {
            throw new Error('Required parameter userPrincipalId was null or undefined when calling policyApiV1PoliciesUpdatemsisdnbypolicynumberPut.');
        }
        const product = requestParameters.product;
        const updateMsisdnByPolicyNumberDto = requestParameters.updateMsisdnByPolicyNumberDto;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }

        let localVarHeaders = this.defaultHeaders;
        if (correlationId !== undefined && correlationId !== null) {
            localVarHeaders = localVarHeaders.set('CorrelationId', String(correlationId));
        }
        if (userPrincipalId !== undefined && userPrincipalId !== null) {
            localVarHeaders = localVarHeaders.set('UserPrincipalId', String(userPrincipalId));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/policy/api/v1/policies/updatemsisdnbypolicynumber`;
        return this.httpClient.request<EmptyResultApiResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateMsisdnByPolicyNumberDto,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update promotion code in contract by MSISDN.
     * This method updates a promotion code in a contract identified by its MSISDN.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyApiV1PoliciesUpdatepromotioncodebymsisdnPatch(requestParameters: PolicyApiV1PoliciesUpdatepromotioncodebymsisdnPatchRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<EmptyResultApiResponse>;
    public policyApiV1PoliciesUpdatepromotioncodebymsisdnPatch(requestParameters: PolicyApiV1PoliciesUpdatepromotioncodebymsisdnPatchRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<EmptyResultApiResponse>>;
    public policyApiV1PoliciesUpdatepromotioncodebymsisdnPatch(requestParameters: PolicyApiV1PoliciesUpdatepromotioncodebymsisdnPatchRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<EmptyResultApiResponse>>;
    public policyApiV1PoliciesUpdatepromotioncodebymsisdnPatch(requestParameters: PolicyApiV1PoliciesUpdatepromotioncodebymsisdnPatchRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const correlationId = requestParameters.correlationId;
        if (correlationId === null || correlationId === undefined) {
            throw new Error('Required parameter correlationId was null or undefined when calling policyApiV1PoliciesUpdatepromotioncodebymsisdnPatch.');
        }
        const userPrincipalId = requestParameters.userPrincipalId;
        if (userPrincipalId === null || userPrincipalId === undefined) {
            throw new Error('Required parameter userPrincipalId was null or undefined when calling policyApiV1PoliciesUpdatepromotioncodebymsisdnPatch.');
        }
        const product = requestParameters.product;
        const updatePromotionCodeByMsisdnDto = requestParameters.updatePromotionCodeByMsisdnDto;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }

        let localVarHeaders = this.defaultHeaders;
        if (correlationId !== undefined && correlationId !== null) {
            localVarHeaders = localVarHeaders.set('CorrelationId', String(correlationId));
        }
        if (userPrincipalId !== undefined && userPrincipalId !== null) {
            localVarHeaders = localVarHeaders.set('UserPrincipalId', String(userPrincipalId));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/policy/api/v1/policies/updatepromotioncodebymsisdn`;
        return this.httpClient.request<EmptyResultApiResponse>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updatePromotionCodeByMsisdnDto,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update contract status
     * Sample request                    PUT /UpdateStatus      {          \&quot;Msisdn\&quot;:\&quot;431234567890\&quot;,          \&quot;Status\&quot;:\&quot;Suspended\&quot;,          \&quot;Reason\&quot;:\&quot;TBD\&quot;      }
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyApiV1PoliciesUpdatestatusPut(requestParameters: PolicyApiV1PoliciesUpdatestatusPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<EmptyResultApiResponse>;
    public policyApiV1PoliciesUpdatestatusPut(requestParameters: PolicyApiV1PoliciesUpdatestatusPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<EmptyResultApiResponse>>;
    public policyApiV1PoliciesUpdatestatusPut(requestParameters: PolicyApiV1PoliciesUpdatestatusPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<EmptyResultApiResponse>>;
    public policyApiV1PoliciesUpdatestatusPut(requestParameters: PolicyApiV1PoliciesUpdatestatusPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const correlationId = requestParameters.correlationId;
        if (correlationId === null || correlationId === undefined) {
            throw new Error('Required parameter correlationId was null or undefined when calling policyApiV1PoliciesUpdatestatusPut.');
        }
        const userPrincipalId = requestParameters.userPrincipalId;
        if (userPrincipalId === null || userPrincipalId === undefined) {
            throw new Error('Required parameter userPrincipalId was null or undefined when calling policyApiV1PoliciesUpdatestatusPut.');
        }
        const product = requestParameters.product;
        const updateContractStatusDto = requestParameters.updateContractStatusDto;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }

        let localVarHeaders = this.defaultHeaders;
        if (correlationId !== undefined && correlationId !== null) {
            localVarHeaders = localVarHeaders.set('CorrelationId', String(correlationId));
        }
        if (userPrincipalId !== undefined && userPrincipalId !== null) {
            localVarHeaders = localVarHeaders.set('UserPrincipalId', String(userPrincipalId));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/policy/api/v1/policies/updatestatus`;
        return this.httpClient.request<EmptyResultApiResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateContractStatusDto,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update tariff in contract by MSISDN.
     * This method updates the customer’s mobile phone tariff in the contract identified by it\&#39;s MSISDN.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyApiV1PoliciesUpdatetariffbymsisdnPut(requestParameters: PolicyApiV1PoliciesUpdatetariffbymsisdnPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<EmptyResultApiResponse>;
    public policyApiV1PoliciesUpdatetariffbymsisdnPut(requestParameters: PolicyApiV1PoliciesUpdatetariffbymsisdnPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<EmptyResultApiResponse>>;
    public policyApiV1PoliciesUpdatetariffbymsisdnPut(requestParameters: PolicyApiV1PoliciesUpdatetariffbymsisdnPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<EmptyResultApiResponse>>;
    public policyApiV1PoliciesUpdatetariffbymsisdnPut(requestParameters: PolicyApiV1PoliciesUpdatetariffbymsisdnPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const correlationId = requestParameters.correlationId;
        if (correlationId === null || correlationId === undefined) {
            throw new Error('Required parameter correlationId was null or undefined when calling policyApiV1PoliciesUpdatetariffbymsisdnPut.');
        }
        const userPrincipalId = requestParameters.userPrincipalId;
        if (userPrincipalId === null || userPrincipalId === undefined) {
            throw new Error('Required parameter userPrincipalId was null or undefined when calling policyApiV1PoliciesUpdatetariffbymsisdnPut.');
        }
        const product = requestParameters.product;
        const updateTariffByMsisdnDto = requestParameters.updateTariffByMsisdnDto;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }

        let localVarHeaders = this.defaultHeaders;
        if (correlationId !== undefined && correlationId !== null) {
            localVarHeaders = localVarHeaders.set('CorrelationId', String(correlationId));
        }
        if (userPrincipalId !== undefined && userPrincipalId !== null) {
            localVarHeaders = localVarHeaders.set('UserPrincipalId', String(userPrincipalId));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/policy/api/v1/policies/updatetariffbymsisdn`;
        return this.httpClient.request<EmptyResultApiResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateTariffByMsisdnDto,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update tariff in contract by policy number.
     * This method updates the customer’s mobile phone tariff in the contract identified by its policy number.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public policyApiV1PoliciesUpdatetariffbypolicynumberPut(requestParameters: PolicyApiV1PoliciesUpdatetariffbypolicynumberPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<EmptyResultApiResponse>;
    public policyApiV1PoliciesUpdatetariffbypolicynumberPut(requestParameters: PolicyApiV1PoliciesUpdatetariffbypolicynumberPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<EmptyResultApiResponse>>;
    public policyApiV1PoliciesUpdatetariffbypolicynumberPut(requestParameters: PolicyApiV1PoliciesUpdatetariffbypolicynumberPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<EmptyResultApiResponse>>;
    public policyApiV1PoliciesUpdatetariffbypolicynumberPut(requestParameters: PolicyApiV1PoliciesUpdatetariffbypolicynumberPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const correlationId = requestParameters.correlationId;
        if (correlationId === null || correlationId === undefined) {
            throw new Error('Required parameter correlationId was null or undefined when calling policyApiV1PoliciesUpdatetariffbypolicynumberPut.');
        }
        const userPrincipalId = requestParameters.userPrincipalId;
        if (userPrincipalId === null || userPrincipalId === undefined) {
            throw new Error('Required parameter userPrincipalId was null or undefined when calling policyApiV1PoliciesUpdatetariffbypolicynumberPut.');
        }
        const product = requestParameters.product;
        const updateTariffByPolicyNumberDto = requestParameters.updateTariffByPolicyNumberDto;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }

        let localVarHeaders = this.defaultHeaders;
        if (correlationId !== undefined && correlationId !== null) {
            localVarHeaders = localVarHeaders.set('CorrelationId', String(correlationId));
        }
        if (userPrincipalId !== undefined && userPrincipalId !== null) {
            localVarHeaders = localVarHeaders.set('UserPrincipalId', String(userPrincipalId));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/policy/api/v1/policies/updatetariffbypolicynumber`;
        return this.httpClient.request<EmptyResultApiResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: updateTariffByPolicyNumberDto,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
