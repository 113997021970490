/**
 * Policy Services API
 * The Policy Services API provides methods for activating new as well as managing and canceling existing insurance contracts.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CancelationReason = 'BaseContractCanceled' | 'ContractTakeOver' | 'UnsupportedNewTariff' | 'NoNeed' | 'PremiumTooHigh' | 'DeductibleTooHigh' | 'WrongShopInformation' | 'Complaint' | 'DeniedClaim' | 'InsuredRiskCeased' | 'TooManyClaims' | 'MainInsuredAgeLimitReached';

export const CancelationReason = {
    BaseContractCanceled: 'BaseContractCanceled' as CancelationReason,
    ContractTakeOver: 'ContractTakeOver' as CancelationReason,
    UnsupportedNewTariff: 'UnsupportedNewTariff' as CancelationReason,
    NoNeed: 'NoNeed' as CancelationReason,
    PremiumTooHigh: 'PremiumTooHigh' as CancelationReason,
    DeductibleTooHigh: 'DeductibleTooHigh' as CancelationReason,
    WrongShopInformation: 'WrongShopInformation' as CancelationReason,
    Complaint: 'Complaint' as CancelationReason,
    DeniedClaim: 'DeniedClaim' as CancelationReason,
    InsuredRiskCeased: 'InsuredRiskCeased' as CancelationReason,
    TooManyClaims: 'TooManyClaims' as CancelationReason,
    MainInsuredAgeLimitReached: 'MainInsuredAgeLimitReached' as CancelationReason
};

